<template>
  <div>
    <!-- No project memberships and finished loading -->
    <div v-if="!loading && projects.length === 0">
      <!-- Showing more guided version -->
      <page-title>Welcome to Synpse!</page-title>

      <v-row class="mt-2">
        <v-col class="d-flex" md="6" sm="12" xs="12">
          <v-card width="100%" class="mt-4">
            <v-card-title>Getting Started</v-card-title>
            <v-card-text>
              Synpse is a system that let's you organize your devices, perform maintenance
              actions on them and easily deploy applications in a stateless, cloud-native way.
              In Synpse, all your devices and applications belong to a project. Projects can
              be shared between users.
              <br/>
              <br/>
              Typical flow:
              <ol>
                <li>Create a project</li>
                <li>Add one or more devices to your project</li>
                <li>Deploy an application</li>
              </ol>
            </v-card-text>
            <v-card-text>
              Useful Resources:
              <ul>
                <li><a href="https://docs.synpse.net" rel="noopener" target="_blank">Documentation</a></li>
                <li><a href="https://discord.com/invite/dkgN4vVNdm" rel="noopener" target="_blank">Discord Community</a></li>
                <li><a href="https://github.com/synpse-hq/synpse" rel="noopener" target="_blank">Examples</a></li>
              </ul>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <project-create></project-create>
            </v-card-actions>
          </v-card>
        </v-col>

        <v-col class="d-flex" md="6" sm="12" xs="12">
          <v-card width="100%" class="mt-4">
            <v-card-title>What is Synpse?</v-card-title>
            <v-card-text>
              Synpse is a complete service to <a href="https://docs.synpse.net/devices/provisioning" rel="noopener" target="_blank">provision</a>,
              <a href="https://docs.synpse.net/synpse-core/devices" rel="noopener" target="_blank">manage devices</a> and
              <a href="https://docs.synpse.net/applications/deploy" rel="noopener" target="_blank">deploy applications</a>. We provide utilities to
              prepare images for bulk provisioning, filter and label your devices, conditionally deploy application
              on all or a subset of your devices.
            </v-card-text>
            <v-card-text>
              In Synpse you store your application configuration in a popular yaml format that can be easily stored
              in popular version control services like GitHub, Gitlab or Bitbucket. Sensitive application configuration
              can be stored in <a href="https://docs.synpse.net/applications/secrets" rel="noopener" target="_blank">secrets</a>.
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <div v-else>
      <!-- Showing projects -->
      <v-layout>
        <div class="ml-4 mt-1 mb-2">
          <v-btn class="primary--text mr-3" text @click="refresh()">
            <v-icon class="mr-1">refresh</v-icon>
            Refresh
          </v-btn>
          <project-create></project-create>
        </div>
      </v-layout>

      <v-layout>
        <page-subtitle>
          Projects are used to manage device groups, their provisioning and
          deployed applications.
        </page-subtitle>
      </v-layout>

      <!-- Error message -->
      <v-layout>
        <div v-if="error">
          <v-alert type="error" dense outlined>
            {{ error }}
          </v-alert>
        </div>
      </v-layout>

      <v-layout class="mt-0">
        <div class="tbcard">
          <div>
            <v-layout row>
              <v-flex
                xs12
                md6
                lg4
                v-for="(membership, i) in projects"
                :key="i"
                class="mt-5"
              >
                <v-card class="mx-auto mb-3 mr-3 ml-3" flat outlined>
                  <v-card-title class="headline">
                    Project: {{ membership.project.name }}
                    <v-spacer></v-spacer>

                    <v-menu
                      class="items"
                      top
                      left
                      transition="v-slide-y-transition"
                    >
                      <template v-slot:activator="{ on: on }">
                        <v-btn
                          icon
                          v-on="on"
                          :class="
                            $store.state.theme === 'light'
                              ? 'secondary--text'
                              : ''
                          "
                        >
                          <v-icon>more_vert</v-icon>
                        </v-btn>
                      </template>

                      <v-list dense>
                        <v-list-item
                         :to="{
                            name: 'projectDetails',
                            params: {
                              project: membership.project.id,
                            },
                          }"
                          router
                        >
                          <v-list-item-title>Details</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-card-title>
                  <v-card-text>
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">
                              Resource
                            </th>
                            <th class="text-right">
                              Quota
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Devices</td>
                            <td class="text-right">
                              {{ membership.project.deviceCount }}/{{ membership.project.quota.devices }}
                            </td>
                          </tr>
                          <tr>
                            <td>Namespaces</td>
                            <td class="text-right">
                              {{ membership.project.namespaceCount }}/{{ membership.project.quota.namespaces }}
                            </td>
                          </tr>
                          <tr>
                            <td>Applications</td>
                            <td class="text-right">
                              {{ membership.project.applicationCount }}/{{ membership.project.quota.applications }}
                            </td>
                          </tr>
                          <tr>
                            <td>Secrets</td>
                            <td class="text-right">
                              {{ membership.project.secretCount }}/{{ membership.project.quota.secrets }}
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn
                      class="primary--text mr-3"
                      outlined
                      :disabled="membership.project.id === selectedProjectId ? true : false"
                      @click="selectProject(membership)"
                    >
                      <v-icon class="mr-1">touch_app</v-icon>
                      <span v-if="membership.project.id === selectedProjectId">
                        Selected
                      </span>
                      <span v-else>
                        Select Project
                      </span>
                    </v-btn>

                    <v-spacer></v-spacer>                    

                    <v-btn
                      class="primary--text mr-3"

                      outlined
                      :to="{
                        name: 'projectDetails',
                        params: {
                          project: membership.project.id,
                        },
                      }"
                      router
                    >
                      Manage
                    </v-btn>

                    <v-btn
                      :class="membership.project.deviceCount == 0 ? 'light-blue--text lighten-2 mr-3' : 'primary--text mr-3'"
                      outlined
                      :to="{
                        name: 'devices',
                        params: {
                          project: membership.project.id,
                        },
                      }"
                      router
                    >
                      Add device
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-flex>
            </v-layout>
          </div>
        </div>
      </v-layout>
    </div>
  </div>
</template>

<style lang="stylus">
.tbcard {
  width: 100%;
  display: block;
}
</style>


<script>
import PageTitle from '../PageTitle';
import PageSubtitle from '../PageSubtitle';
import ProjectCreate from '../projects/ProjectCreate.vue';

export default {
  components: {
    PageTitle,
    PageSubtitle,
    ProjectCreate
  },

  data() {
    return {
      selectedProject: null,
      removeProjectModal: false,
      removingProject: {
        id: ''
      },
    }
  },

  watch: {
    removeProjectModal(val) {
      val || this.cancelDeletion()
    },
  },

  computed: {
    projects() {
      return this.$store.state.project.memberships
    },
    loading() {
      return this.$store.state.project.loading
    },
    error() {
      return this.$store.state.project.error
    },
    selectedProjectId() {
      return this.$store.state.project.selectedProjectId
    },
    selectedNamespace() {
      return this.$store.state.namespace.selectedProjectId
    }
  },

  mounted() {
    this.initializeProject()
  },

  methods: {
    refresh() {
      this.$store.dispatch('GetProjectMemberships')
    },
    selectProject(selectedProject) {
      this.$store.dispatch('SelectProject', selectedProject.projectId)
      this.$store.dispatch('Notify', `Selected project ${selectedProject.projectId}`)
    },
    async initializeProject() {
      await this.$store.dispatch('GetProjectMemberships')
      await this.$store.dispatch('LoadSelectedProject')
      if (!this.selectedProjectId) {
        let first = this.$store.getters.getFirstProject()
        this.$store.dispatch('SelectProject', first)
      }

      if (this.projects.length > 0) {
        await this.loadProjecNamespaces()
      }
    },
    async loadProjecNamespaces() {
      let q = {
        project: this.selectedProjectId
      }
      await this.$store.dispatch('ListNamespaces', q)
      this.$store.dispatch('LoadSelectedNamespace')
    }
  }
}
</script>
